<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary me-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
               
              @click="$router.push({name: 'admin.labour-hazards.create'})"
            ><i data-feather="plus" /></a>
            <a
              class="btn btn-icon btn-dark"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
               
              @click="$router.push({name: 'admin.labour-hazards.shipments'})"
            ><i data-feather="send" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <!-- Group & filter -->
        <div class="card card--senior-call-header">
          <div class="card-header">
            <h3 class="cart-title">
              <a data-action="collapse">Group and filter</a>
            </h3>
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li>
                  <a
                    v-b-toggle="`collapseFilters`"
                    data-action="collapse"
                  ><i data-feather="chevron-down" /></a>
                </li>
              </ul>
            </div>
          </div>
          <b-collapse
            :id="`collapseFilters`"
            visible
          >
            <div class="card-body">
              <div class="row d-flex align-items-center justify-content-between">
                <div class="col-sm-9 col-xl-10">
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Grouped by:</label>
                    <select
                      class="form-select"
                      @change="groupData($event.target.value)"
                    >
                      <option value="">
                        No grouping
                      </option>
                      <option value="section">
                        By Section
                      </option>
                      <option value="subsection">
                        By Subsection
                      </option>
                      <option value="researcher">
                        By Researcher
                      </option>
                      <option value="host">
                        By Host
                      </option>
                      <option value="workplaces">
                        By Workplaces
                      </option>
                      <option value="hazards">
                        By Hazards
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-3 col-xl-2 d-flex justify-content-end">
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Open filters"
                  ><a
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasFilters"
                    aria-controls="offcanvasFilters"
                    @click="openFilters()"
                  ><i data-feather="filter" /></a></span>
                  <a
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Export to Excel"
                    @click="exportData"
                  ><i data-feather="file" /></a>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Table settings"
                  ><a
                    href="#"
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasTableSettings"
                    aria-controls="offcanvasTableSettings"
                    @click="openTableSettings()"
                  ><i data-feather="settings" /></a></span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col">
                  Applied Filters:
                  <span
                    v-for="filter, index in filters"
                    :key="index"
                    class="badge badge-light-dark ms-1"
                  >
                    <template v-if="Array.isArray(filter)">
                      <template
                        v-for="str in filter"
                      >
                        {{ str.name }}
                        <a
                          :key="str.name"
                          class="ms-1 text-black"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Delete filter"
                          @click="deleteFilter(index)"
                        ><i data-feather="x" /></a>
                      </template>
                    </template>
                    <template v-else-if="typeof filter === 'object' && filter">
                      {{ filter.name }}
                      <a
                        class="ms-1 text-black"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete filter"
                        @click="deleteFilter(index)"
                      ><i data-feather="x" /></a>
                    </template>
                    <template v-else-if="filter">
                      {{ filter }}
                      <a
                        class="ms-1 text-black"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete filter"
                        @click="deleteFilter(index)"
                      ><i data-feather="x" /></a>
                    </template>
                  </span>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <!-- END Group & filter -->
        <!-- All items -->
        <div class="row">
          <div
            v-if="!loading"
            class="col-12"
          >
            <GroupingTable
              v-if="!grouping"
              :data="hazardFiles"
              :sorted-field="sortedField"
              :sort-mapping="sortMapping"
              :title="'All items'"
              :total="hazardTotal"
              :fetch-path="'labourHazards/fetchFiles'"
            />
            <template v-if="grouping">
              <div class="card shadow-none bg-transparent mb-0 ms-0">
                <div class="card-body ps-0">
                  <a
                    class="text-dark"
                    @click="collapseAll = true"
                  ><i data-feather="minimize-2" /> collapse all</a>
                  <a
                    class="ms-2 text-dark"
                    @click="collapseAll = false"
                  ><i data-feather="maximize-2" /> uncollapse all</a>
                </div>
              </div>
              <GroupingTable
                v-for="data, index in groupedData"
                :key="data.id"
                :data="data.data"
                :sorted-field="sortedField"
                :sort-mapping="sortMapping"
                :title="data.title"
                :total="data.data.length"
                :fetch-path="'labourHazards/fetchFiles'"
                :table-id="index"
                :show="collapseAll"
              />
            </template>
          </div>
          <div
            v-if="loading"
            id="loading-bg"
          >
            <div
              class="loading"
            >
              <div class="effect-1 effects" />
              <div class="effect-2 effects" />
              <div class="effect-3 effects" />
            </div>
          </div>
        </div>
        <!-- END All items -->
      </div>
    </div>
    <ConvoFilter
      :filter-path="grouping ? 'labourHazards/groupingFilter' : 'labourHazards/filter'"
    >
      <template #filters>
        <div class="offcanvas-body">
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Researcher</label>
            <v-select
              label="name"
              :options="users"
              :get-option-key="option => option.id"
              @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
              @input="saveFilter($event, 'researcher')"
            />
          </div>
          <hr>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Section</label>
            <v-select
              label="name"
              :options="sections"
              :get-option-key="option => option.id"
              @search="onSearch({ name: $event, type: 'parent' }, 'safeties/filter')"
              @input="saveFilter($event, 'section')"
            />
          </div>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Subsection</label>
            <v-select
              label="name"
              :options="subsections"
              :get-option-key="option => option.id"
              @search="onSearch({ name: $event }, 'safeties/getSubsections')"
              @input="saveFilter($event, 'subsection')"
            />
          </div>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Host</label>
            <v-select
              label="name"
              :options="hosts"
              :get-option-key="option => option.id"
              @search="onSearch({ name: $event }, 'institutions/filter')"
              @input="saveFilter($event, 'host')"
            />
          </div>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Workplaces</label>
            <v-select
              label="name"
              :options="workplaces"
              :get-option-key="option => option.id"
              @search="onSearch({ name: $event }, 'workplaces/filter')"
              @input="saveFilter($event, 'workplace')"
            />
          </div>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Hazards</label>
            <v-select
              label="name"
              :options="hazards"
              :get-option-key="option => option.id"
              @search="onSearch({ name: $event }, 'hazards/filter')"
              @input="saveFilter($event, 'hazard')"
            />
          </div>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Title</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              @change="saveFilter($event.target.value, 'title')"
            >
          </div>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Year</label>
            <date-picker
              v-model="filters['year']"
              format="Y"
              type="year"
              value-type="format"
              class="w-100"
              @change="saveFilter($event, 'year')"
            />
          </div>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Renew</label>
            <date-picker
              v-model="filters['renew_year']"
              format="Y"
              type="year"
              value-type="format"
              class="w-100"
              @change="saveFilter($event, 'renew_year')"
            />
          </div>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Need feedback</label>
            <select
              id=""
              name=""
              class="form-select"
              @input="saveFilter($event.target.value, 'need_feedback')"
            >
              <option value="">
                All
              </option>
              <option value="true">
                Yes
              </option>
              <option value="false">
                No
              </option>
            </select>
          </div>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
              @input="saveFilter($event.target.value, 'status')"
            >Status</label>
            <select
              id=""
              name=""
              class="form-select"
            >
              <option value="">
                All
              </option>
              <option value="">
                Signed
              </option>
              <option value="">
                Pending
              </option>
            </select>
          </div>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >From year</label>
            <date-picker
              v-model="filters['from_year']"
              format="Y"
              type="year"
              value-type="format"
              class="w-100"
              @change="saveFilter($event, 'from_year')"
            />
          </div>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >To year</label>
            <date-picker
              v-model="filters['to_year']"
              format="Y"
              type="year"
              value-type="format"
              class="w-100"
              @change="saveFilter($event, 'to_year')"
            />
          </div>
          <div class="mb-1">
            <div class="form-check form-check-success form-switch d-flex align-items-center">
              <input
                id="publishContent"
                type="checkbox"
                value="View Archived"
                class="form-check-input"
                @change="saveFilter($event.target.value, 'view_archived')"
              >
              <label
                class="form-check-label"
                for=""
              ><small class="ms-1">View archived documents <em
                id=""
                class="ms-2 text-muted"
              /></small></label>
            </div>
          </div>
        </div>
      </template>
    </ConvoFilter>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { BCollapse } from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import GroupingTable from '../../partials/components/GroupingTable.vue'
import ConvoFilter from '../../partials/offcanvas/ConvoFilter.vue'

export default {
  components: {
    ConvoFilter,
    GroupingTable,
    BCollapse,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Surname',
          checked: true,
          order: 2,
        },
        {
          name: 'NIF/NIE/PASS',
          checked: true,
          order: 3,
        },
        {
          name: 'Birthday',
          checked: true,
          order: 4,
        },
        {
          name: 'Genre',
          checked: true,
          order: 5,
        },
        {
          name: 'Email',
          checked: true,
          order: 6,
        },
        {
          name: 'Section',
          checked: true,
          order: 7,
        },
        {
          name: 'Subsection',
          checked: true,
          order: 8,
        },
        {
          name: 'Title',
          checked: true,
          order: 9,
        },
        {
          name: 'Year',
          checked: true,
          order: 10,
        },
        {
          name: 'Renew',
          checked: true,
          order: 11,
        },
        {
          name: 'Documents',
          checked: true,
          order: 12,
        },
        {
          name: 'Need feedback',
          checked: true,
          order: 13,
        },
        {
          name: 'Status',
          checked: true,
          order: 14,
        },
        {
          name: 'Host',
          checked: true,
          order: 15,
        },
        {
          name: 'Workplaces',
          checked: true,
          order: 16,
        },
        {
          name: 'Hazards',
          checked: true,
          order: 17,
        },
        {
          name: 'Published',
          checked: true,
          order: 18,
        },
        {
          name: 'Updated',
          checked: true,
          order: 19,
        },
        {
          name: 'Archived',
          checked: true,
          order: 20,
        },
      ],
      sortMapping: {
        Name: 'hazard.user.first_name',
        Surname: 'hazard.user.last_name',
        'NIF/NIE/PASS': 'hazard.user.identify_document',
        Birthday: 'hazard.user.birthday',
        Genre: 'hazard.user.genre',
        Email: 'hazard.user.email',
        Section: 'hazard.section.code',
        Subsection: 'hazard.subsections_names',
        Title: 'title',
        Year: 'year',
        Renew: 'renew_year',
        Documents: 'hazard.files_h.length',
        'Need feedback': 'need_feedback',
        Status: 'status',
        Host: 'hosts',
        Workplaces: 'workplaces',
        Hazards: 'hazards',
        Published: 'published',
        Updated: 'updated_at',
        Archived: 'archived_date',
      },
      grouping: null,
      title: 'Occupational documents',
      loading: false,
      collapseAll: false,
    }
  },
  computed: {
    ...mapGetters({
      filters: 'filters/filters',
      fields: 'modals/tableSettingsFields',
      hazardFiles: 'labourHazards/labourHazardFiles',
      hazardTotal: 'labourHazards/labourHazardFilesTotal',
      groupedData: 'labourHazards/groupedData',
      // Filters
      users: 'users/fakeUsers',
      sections: 'safeties/safeties',
      subsections: 'safeties/subsafeties',
      hosts: 'institutions/institutions',
      workplaces: 'workplaces/workplaces',
      hazards: 'hazards/hazards',
      // END filters
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'labour-hazard')
    await this.$store.dispatch('labourHazards/fetchFiles')

    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'labour-hazard',
      })
    }

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    openFilters() {
      this.$store.dispatch('modals/toggleConvoFilters', true)
    },
    openTableSettings() {
      this.$store.dispatch('modals/toggleTableSettings', true)
    },
    async exportData() {
      await this.$store.dispatch('labourHazards/exportFiles', { filters: this.$store.getters['filters/filters'], grouping: this.grouping })
    },
    async groupData(data) {
      this.loading = true
      this.grouping = data

      if (data) {
        await this.$store.dispatch('labourHazards/fetchGroupedData', data)
      }

      this.loading = false
    },
    async deleteFilter(index) {
      const { filters } = this
      this.$store.dispatch('filters/deleteFilters', index)

      this.$store.dispatch('filters/saveFilters', this.filters)
      if (this.grouping) {
        await this.$store.dispatch('labourHazards/groupingFilter', this.filters)
      } else {
        await this.$store.dispatch('labourHazards/filter', this.filters)
      }
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 250)
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
